import instance from "./instance"

import authModule from './auth'
import userModule from './user'
import roleModule from './role'
import branchModule from './branch'

export default {
	auth: authModule(instance),
	user: userModule(instance),
	role: roleModule(instance),
	branch: branchModule(instance),
}