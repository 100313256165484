import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/empty',
		name: 'Empty',
		component: {template: ''}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login'),
		meta: {
			layout: 'auth'
		}
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home'),
		meta: {
			auth: true
		},
	},
	{
		path: '/admin/users',
		name: 'AdminUsers',
		component: () => import('../views/admin/Users'),
		meta: {
			layout: 'admin',
			auth: true
		},
	},
	{
		path: '/admin/branches',
		name: 'AdminBranches',
		component: () => import('../views/admin/Branches'),
		meta: {
			layout: 'admin',
			auth: true
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	const loggedIn = localStorage.getItem('user')

	if (to.matched.some(record => record.meta.auth) && !loggedIn) {
		next('/login')
		return
	}
	next()
})

export default router