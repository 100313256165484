import api from "@/api"
import instance from "@/api/instance"

export default {
	state: {
		user: null,
		lists: null
	},
	actions: {
		login({ commit }, credentials) {
			return api.auth.login(credentials)
				.then(({ data }) => {
					commit('setUserData', data)
				})
		},
		logout({ commit }) {
			return api.auth.logout()
				.then(() => {
					commit('clearUserData')
				})
		}
	},
	mutations: {
		setUserData(state, userData) {
			state.user = userData
			state.lists = userData.lists
			localStorage.setItem('user', JSON.stringify(userData))
			instance.defaults.headers.common.Authorization = `Bearer ${userData.token}`
		},
		clearUserData() {
			localStorage.removeItem('user')
		}
	},
	getters: {
		currentUser(state) {
			return state.user.user
		},
		lists(state) {
			return state.lists
		},
		isLogged: state => !!state.user
	},
}