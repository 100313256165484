import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import instance from '@/api/instance'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'

import App from './views/App'

import ApiPlugin from './plugins/api'
import MessagePlugin from './plugins/message'

import InputGroup from './components/form/InputGroup'
import InputActionGroup from './components/form/InputActionGroup'
import VSelect from './components/form/selects/VSelect'
import MultiSelect from './components/form/selects/MultiSelect'
import MultiSelectTwolevel from './components/form/selects/MultiSelectTwolevel'
import Socials from './components/Socials'
import CheckboxesGroup from './components/form/CheckboxesGroup'

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(VueAxios, axios)
Vue.use(ApiPlugin)
Vue.use(MessagePlugin)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-moment'))

Vue.use(PerfectScrollbar, {
	options: {
		wheelSpeed: 0.3,
		wheelPropagation: false
	}
})

Vue.config.productionTip = false

Vue.component('InputGroup', InputGroup)
Vue.component('InputActionGroup', InputActionGroup)
Vue.component('VSelect', VSelect)
Vue.component('MultiSelect', MultiSelect)
Vue.component('MultiSelectTwolevel', MultiSelectTwolevel)
Vue.component('Socials', Socials)
Vue.component('CheckboxesGroup', CheckboxesGroup)

new Vue({
  store,
  router,
	created() {
		const userInfo = localStorage.getItem('user')
		if (userInfo) {
			const userData = JSON.parse(userInfo)
			this.$store.commit('setUserData', userData)
		}
		instance.interceptors.response.use(
			response => response,
			error => {
				if (error.response.status === 401) {
					this.$store.dispatch('logout')
					this.$router.push({ name: 'Login' })
				}
				return Promise.reject(error)
			}
		)
	},
  render: h => h(App),
}).$mount('#app')