export default function(instance) {
	return {
		search(payload) {
			return instance.get('user/search', {params: payload})
		},
		create(payload) {
			return instance.post('user/create', payload)
		},
		update(payload) {
			return instance.put('user/update', payload)
		}
	}
}