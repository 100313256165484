<template>
	<div class="checkboxes-group">
		<label class="checkbox" v-for="item in items" :key="item.id">
			<input type="checkbox" @change="toggleSelectItem(item.id)" :checked="selectedItems !== null ? selectedItems.includes(item.id) : false">
			<span class="box"></span>
			{{ item.name }}
		</label>
	</div>
</template>

<script>
export default {
  props: {
		items: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Array,
      default: null
    }
  },
  computed: {
    selectedItems: function() {
      return this.value
    },
  },
  methods: {
    toggleSelectItem(id) {
      let selectedItems = []
      if(this.selectedItems !== null) selectedItems = this.selectedItems
      let index = selectedItems.indexOf(id)
      if(index == -1) {
        selectedItems.push(id)
      } else {
        selectedItems.splice(index, 1)
      }
      this.$emit('input', selectedItems.length ? selectedItems : null)
    },
  }
}
</script>

<style lang="sass" scoped>

</style>