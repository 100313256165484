export default function(instance) {
	return {
		all() {
			return instance.get('roles')
		},
		update(payload) {
			return instance.put('role/update', payload)
		}
	}
}
