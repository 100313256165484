<template>
	<div class="socials-group">
		<label v-if="label">{{ label }}</label>
		<div class="socials">
			<span v-for="(social, index) in socials" :key="index" class="social" :style="'background:'+social.background" :class="[{active: social.active}, {filled: social.link}]" @click="selectSocial(index)" v-html="social.icon"></span>
		</div>
		<input-group v-for="(social, index) in socials" :key="index" @input="input" v-model="social.link" :class="{hidden: !socials[index].active}" placeholder="Введите ссылку" />
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters(['lists'])
	},
	watch: {
		value: {
			handler: function(newValue, oldValue) {
				this.socials.forEach(social => {
					let link = null
					if(newValue !== null) {
						let linkItem = newValue.find(linkItem => linkItem.id == social.id)
						if(linkItem !== undefined) link = linkItem.link
					}
					Vue.set(social, 'link', link)
				})
			},
			deep: true,
    }
	},
	data() {
		return {
			socials: []
		}
	},
	props: {
    label: {
      type: String,
      default: ''
    },
		socialsShow: {
      type: Array,
      default() {
        return []
      }
    },
		value: {
      type: Array,
      default: null
    }
  },
	created() {
		this.socials = this.lists.socials.filter(social => this.socialsShow.indexOf(social.id) !== -1)
  	this.socials.forEach(social => {
			Vue.set(social, 'active', false)
			let link = null
			Vue.set(social, 'link', link)
		})
		this.socials[0].active = true
  },
	methods: {
		selectSocial(index) {
			this.socials.find(i => i.active = false)
			this.socials[index].active = true
		},
		input() {
			let socials = []
			this.socials.find(social => {
				if(social.link) socials.push({id: social.id, link: social.link})
			})
			if(!socials.length) socials = null
			this.$emit('input', socials)
		}
	}
}
</script>

<style lang="sass">
.socials-group
	label
		font-size: 16px
		font-weight: 700
		margin-bottom: 3px
	.socials
		display: flex
		gap: 0 10px
		.social
			position: relative
			display: flex
			align-items: center
			justify-content: center
			width: 40px
			height: 40px
			border-radius: 50%
			cursor: pointer
			&.active
				&::before
					position: absolute
					bottom: -10px
					content: ''
					border: 4px solid transparent
					border-bottom: 4px solid #B7B4B5
			&.filled
				border: 2px solid #04B67D
				&.active
					&::before
						bottom: -12px
				&::after
					content: url(~@/assets/img/icons/check-social-filled.svg)
					position: absolute
					right: -3px
					bottom: -3px
					display: flex
					align-items: center
					justify-content: center
					width: 16px
					height: 16px
					background: #04B67D
					border-radius: 50%
</style>