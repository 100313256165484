<template>
  <div class="select-group" :class="groupClass" :style="'min-width:'+minWidth+';max-width:'+maxWidth">
    <label v-if="label">{{ label }}</label>
    <div class="select" :class="{open: dropdownOpened}" v-click-outside="dropdownClose">
      <div class="selected-item" @click="DropdownToggle">
        <input v-if="search && dropdownOpened" v-model="query" v-focus type="text" class="search" placeholder="Поиск..." @click.stop>
        <span class="text">{{ selectedNames || placeholder }}</span>
        <svg class="arrow" width="5" height="5" viewBox="0 0 5 5" fill="none" style="opacity:0.7">
          <path d="M2.71083 4.57612L4.96764 0.664847C5.06 0.50422 4.94355 0.303436 4.75883 0.303436L0.241189 0.303436C0.056468 0.303436 -0.0599871 0.50422 0.0323739 0.664847L2.28918 4.57612C2.3856 4.73674 2.61847 4.73674 2.71083 4.57612Z" fill="black"/>
        </svg>
      </div>
      <div v-if="dropdownOpened" class="dropdown">
        <perfect-scrollbar>
          <ul class="list">
            <li class="item" v-for="item in itemsFiltered" :key="item.id">
              <label class="checkbox">
                <input type="checkbox" @change="toggleSelectItem(item.id)" :checked="selectedIds !== null ? selectedIds.includes(item.id) : false">
                <span class="box"></span>
                {{ item.name }}
              </label>
            </li>
          </ul>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
	data() {
    return {
      dropdownOpened: false,
      query: '',
    }
  },
  props: {
    minWidth: {
      type: String,
      default: 'auto'
    },
		maxWidth: {
      type: String,
      default: 'auto'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Array,
      default: null
    },
    groupClass: {
      type: String,
      default: ''
    },
    search: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(({name}) => name.toLowerCase().includes(this.query.toLowerCase()))
    },
    selectedIds: function() {
      return this.value
    },
    selectedNames() {
      if(this.selectedIds == null) return ''
      return this.items.filter(item => this.selectedIds.includes(item.id)).map(item => {return item.name}).join(', ')
    }
  },
	methods: {
		DropdownToggle() {
      this.dropdownOpened = !this.dropdownOpened
    },
    dropdownClose() {
      this.dropdownOpened = false
      this.query = ''
    },
    toggleSelectItem(id) {
      let selectedIds = []
      if(this.selectedIds !== null) selectedIds = this.selectedIds
      let index = selectedIds.indexOf(id)
      if(index == -1) {
        selectedIds.push(id)
      } else {
        selectedIds.splice(index, 1)
      }
      this.$emit('input', selectedIds.length ? selectedIds : null)
    }
  },
  directives: {
    ClickOutside,
    focus: {
      inserted: function(el) {
        el.focus()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.select-group
  width: 100%
  flex: 1
  >label
    font-size: 16px
    font-weight: 700
    margin-bottom: 3px
  .select
    position: relative
    &.open
      .selected-item
        .arrow
          transform: rotate(180deg)
          top: -1px
    .checkbox
      padding: 2px 0
    .selected-item
      position: relative
      display: flex
      align-items: center
      justify-content: space-between
      padding: 0 15px
      width: 100%
      height: 40px
      background: #F2F3F5
      border: 1px solid #B7B4B5
      border-radius: 5px
      cursor: pointer
      .search
        position: absolute
        top: 0
        left: 0
        background: #F2F3F5
        border: none
        border-radius: 5px
        width: 100%
        height: 100%
        padding: 0 15px
        z-index: 1
      .text
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
      .arrow
        position: relative
        flex-shrink: 0
        margin-left: 15px
    .dropdown
      width: 100%
      border-radius: 5px
      position: absolute
      top: 40px
      box-shadow: 0px 0px 10px rgba(57, 45, 43, 0.27)
      background: #fff
      padding: 13px 15px
      z-index: 9
      .list
        margin: 0
        padding: 0
        .item
          line-height: 17px
          transition: 0.3s
          .first-level
            font-weight: 600
            color: #04B67D
            text-transform: uppercase
          & > label
            padding: 6px 0
            transition: 0.3s
            &:hover
              color: #04B67D
          &.selected
            color: #04B67D
</style>