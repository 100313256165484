<template>
 <div class="input-group" :class="groupClass" :style="'min-width:'+minWidth+';max-width:'+maxWidth">
  <label v-if="label">{{ label }}</label>
	<input v-if="mask" type="text" v-mask="mask" :value="value" @input="input" :placeholder="placeholder">
	<input v-else type="text" :value="value" @input="input" :placeholder="placeholder">
</div>
</template>

<script>
export default {
  props: {
		minWidth: {
      type: String,
      default: 'auto'
    },
		maxWidth: {
      type: String,
      default: 'auto'
    },
    label: {
      type: String,
      default: ''
    },
		mask: {
			type: String,
      default: ''
		},
    groupClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
		value: {
			type: [String, Number],
      default: ''
		}
  },
	methods: {
		input(e) {
			let value = e.target.value
			this.$emit('input', value)
		}
	}
}
</script>

<style lang="sass" scoped>
.input-group
	width: 100%
	flex: 1
	>label
		font-size: 16px
		font-weight: 700
		margin-bottom: 3px
	input
		width: 100%
		height: 40px
		background: #f2f3f5
		border-radius: 5px
		border: 1px solid #B7B4B5
		padding: 0 15px
	&.error
		input
			border-color: red
</style>