<template>
 <div class="input-action-group" :class="groupClass" :style="'min-width:'+minWidth+';max-width:'+maxWidth">
  <label v-if="label">{{ label }}</label>
	<div class="item" v-for="(item, index) in items" :key="index">
		<input v-if="mask" type="text" v-mask="mask" :placeholder="placeholder" v-model="items[index]">
		<input v-else type="text" :placeholder="placeholder" v-model="items[index]">
		<div class="actions">
			<span v-if="index + 1 == items.length" class="action add" :class="{small: actionSmall}" @click="addItem()"></span>
			<span class="action remove" :class="{small: actionSmall}" @click="removeItem(index)"></span>
		</div>
	</div>
</div>
</template>

<script>
export default {
	data() {
		return {
			items: (this.value !== null && this.value.length > 0) ? this.value : ['']
		}
	},
  props: {
		minWidth: {
      type: String,
      default: 'auto'
    },
		maxWidth: {
      type: String,
      default: 'auto'
    },
    label: {
      type: String,
      default: ''
    },
		mask: {
			type: String,
      default: ''
		},
    groupClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
		value: {
			type: Array,
      default: null
		},
		actionSmall: {
      type: Boolean,
      default: false
    },
  },
	watch: {
		value: function(newItems) {
			newItems = newItems ? newItems : ['']
			let arr = []
			newItems.forEach(item => arr.push(item))
			this.items = arr
		},
		items: function(newItems) {
			if(this.value !== null && this.value.length == 0) this.$emit('input', null)
			let value = this.value ? this.value : ['']
			let filteredValue = value.filter(item => item != '')
			let filteredItems = newItems.filter(item => item != '')
			if(filteredValue.join('') != filteredItems.join('')) this.$emit('input', filteredItems.length ? filteredItems : null)
		}
	},
	methods: {
		addItem() {
			this.items.push('')
		},
		removeItem(index) {
			this.items.splice(index, 1)
			if(!this.items.length) this.items.push('')
		}
	}
}
</script>

<style lang="sass" scoped>
.input-action-group
	width: 100%
	flex: 1
	>label
		font-size: 16px
		font-weight: 700
		margin-bottom: 3px
	.item
		display: flex
		justify-content: space-between
		margin-bottom: 10px
		&:last-child
			margin-bottom: 0
		input
			width: 100%
			height: 40px
			background: #f2f3f5
			border-radius: 5px
			border: 1px solid #B7B4B5
			padding: 0 15px
		.actions
			display: flex
			gap: 0 5px
			margin-left: 5px
			.action
				display: flex
				align-items: center
				justify-content: center
				width: 40px
				height: 40px
				background: #04B67D
				border-radius: 5px
				cursor: pointer
				&.small
					width: 25px
					&.add::before
						transform: rotate(45deg) scale(0.84)
					&.remove::before
						transform: scale(0.84)
				&.add
					&::before
						content: url(~@/assets/img/icons/close.svg)
						transform: rotate(45deg)
						position: relative
						top: 1px
						left: -1px
				&.remove
					&::before
						content: url(~@/assets/img/icons/close.svg)
						position: relative
						top: 2px
</style>