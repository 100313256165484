import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'animate.css'
Vue.use(VueSweetalert2, {
	confirmButtonColor: '#04B67D',
})

let optionsBase = {
	position: 'top-end',
	showConfirmButton: false,
	toast: true,
	timer: 2000,
	showClass: {
		popup: 'animate__animated animate__fadeInDown'
	},
	hideClass: {
		popup: 'animate__animated animate__fadeOutUp'
	}
}

export default {
	install(Vue) {
		Vue.prototype.$message = function(options) {
			this.$swal(Object.assign({}, optionsBase, options))
		},
		Vue.prototype.$messageError = function(title) {
			let options = Object.assign({}, optionsBase, {icon: 'error', title: title})
			this.$swal(options)
		},
		Vue.prototype.$messageSuccess = function(title) {
			let options = Object.assign({}, optionsBase, {icon: 'success', title: title})
			this.$swal(options)
		}
	}
}