<template>
  <div id="app">
    <vue-extend-layouts />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
export default {
  name: 'App',
  components: { VueExtendLayouts }
}
</script>

<style lang="sass">
@import "~@/assets/sass/fonts.sass"
*
  box-sizing: border-box
.container
  max-width: 1920px
  padding: 0 50px
  margin: 0 auto
body
  margin: 0
  padding: 0
  min-height: 100vh
  font-family: "ProximaNova", sans-serif
  font-size: 14px
  line-height: 1.2
  color: #392d2b
  background: #edeef0
#app
  min-height: 100vh
*:focus,
button:focus
  outline: none
p
  margin-top: 0
a
  color: inherit
  text-decoration: none
  &:hover
    color: inherit
    text-decoration: none
input[type=checkbox],
input[type=radio]
  display: none
.hidden
  display: none
ul
  margin: 0
  padding: 0
  li
    list-style-type: none
.textarea-group
  textarea
    display: flex
    width: 100%
    height: 120px
    background: #F2F3F5
    border: 1px solid #B7B4B5
    border-radius: 5px 5px 0px 5px
    padding: 12px 10px
.button
  width: 100%
  height: 40px
  background: #04B67D
  color: #fff
  font-size: 16px
  font-weight: 700
  text-transform: uppercase
  display: flex
  align-items: center
  justify-content: center
  border-radius: 5px
  border: none
  cursor: pointer
  transition: 0.3s
  &:disabled
    background: #ccc
    cursor: not-allowed
.checkbox
  display: flex
  cursor: pointer
  line-height: 1.2
  margin: 0
  input 
    &+.box
      position: relative
      display: block
      width: 14px
      height: 14px
      border: 1px solid rgba(57,45,43,0.3)
      border-radius: 3px
      margin-right: 7px
      background: #fff
      flex-shrink: 0
    &:checked + .box:before
      content: url(~@/assets/img/icons/check.svg)
      width: 14px
      position: absolute
      left: 0
      bottom: -4px
.radio
  display: flex
  font-size: 14px
  line-height: 1.2
  cursor: pointer
  margin: 0
  input
    &+.box
      position: relative
      display: block
      width: 14px
      height: 14px
      border: 1px solid #B7B4B5
      border-radius: 50%
      margin-right: 7px
    &:checked
      &+.box
        border-color: #04B67D
      &+.box:before
        content: ''
        width: 8px
        height: 8px
        border-radius: 50%
        position: absolute
        top: 2px
        left: 2px
        background: #04B67D
.select
  .ps--active-y
    .list
      & > .item
        padding-right: 15px
  .ps
    max-height: 260px
    &:hover
      .ps__rail-y
        opacity: 1
    .ps__rail-y
      opacity: 1
      background: rgba(4,182,125,0.2)
      border-radius: 30px
      width: 8px
      .ps__thumb-y
        border-radius: 30px
        background: #04B67D
        width: 100%
        right: 0
.swal2-shown .swal2-popup.swal2-toast
  box-shadow: 4px 9px 16px rgb(113 113 113 / 10%)
  animation-duration: 0.5s
  
@media only screen and (max-width : 1280px)
  .container
    padding: 0 15px
</style>